/* eslint-disable */
import React, { Component } from "react";
import PropTypes, { func } from "prop-types";
import { FormattedMessage } from "react-intl";

import TextField from "material-ui/TextField";
import CountrySelector from "./elements/countrySelector";
import USStatesJSON from "../../utils/USStatesCodes.json";
import CanadaStatesJSON from "../../utils/CanadaStatesCodes.json";
import ItalyStatesJSON from "../../utils/ItalyStatesCodes.json";

const StateDropdown = ({ state, statesJSON, handleFieldUpdate }) => (
  <div className="mdl-selectfield">
    <select
      className={state !== "" ? "browser-default selected" : "browser-default"}
      name="state"
      value={state}
      onChange={(e) => {
        handleFieldUpdate("state", statesJSON.states[e.target.selectedIndex - 1].abbr);
      }}
    >
      {
        <FormattedMessage id="checkout.chooseState" defaultMessage="Choose your state/province">
          {(name) => (
            <option key="" value="" disabled>
              {name}
            </option>
          )}
        </FormattedMessage>
      }
      {statesJSON.states.map(({ name, abbr }) => (
        <option value={abbr} key={abbr}>
          {name}
        </option>
      ))}
    </select>
  </div>
);

class AddressForm extends Component {
  render() {
    return (
      <div style={{ minHeight: "225px" }}>
        <form>
          <CountrySelector
            name="country"
            id="address_country"
            className="billingCountry"
            value={this.props.country}
            onChange={this.props.handleFieldUpdate}
          />

          {this.props.country === "US" && (
            <StateDropdown
              state={this.props.state}
              statesJSON={USStatesJSON}
              handleFieldUpdate={this.props.handleFieldUpdate}
            />
          )}

          {this.props.country === "CA" && (
            <StateDropdown
              state={this.props.state}
              statesJSON={CanadaStatesJSON}
              handleFieldUpdate={this.props.handleFieldUpdate}
            />
          )}

          {this.props.country === "IT" && (
            <StateDropdown
              state={this.props.state}
              statesJSON={ItalyStatesJSON}
              handleFieldUpdate={this.props.handleFieldUpdate}
            />
          )}

          <TextField
            name="line_1"
            ref="line_1"
            id="address_line_1"
            style={this.props.styles.textField}
            defaultValue={this.props.line_1}
            hintText={<FormattedMessage id="checkout.addressLine1" defaultMessage="Address line 1" />}
            errorText={this.props.errors.line_1}
            onChange={this.props.handleAddressUpdate}
          />
          <TextField
            name="line_2"
            ref="line_2"
            id="address_line_2"
            className="formLines"
            defaultValue={this.props.line_2}
            hintText={<FormattedMessage id="checkout.addressLine1" defaultMessage="Address line 2" />}
            onChange={this.props.handleAddressUpdate}
          />
          <TextField
            name="city"
            ref="city"
            id="address_city"
            className="formLines"
            defaultValue={this.props.city}
            hintText={<FormattedMessage id="checkout.city" defaultMessage="City" />}
            errorText={this.props.errors.city}
            onChange={this.props.handleAddressUpdate}
          />
          <TextField
            name="zip"
            ref="zip"
            id="address_zip"
            className="formLines"
            defaultValue={this.props.zip}
            hintText={<FormattedMessage id="checkout.postalCode" defaultMessage="ZIP / Postal code" />}
            errorText={this.props.errors.zip}
            onChange={this.props.handleAddressUpdate}
          />
        </form>
      </div>
    );
  }
}

AddressForm.propTypes = {
  handleAddressUpdate: PropTypes.func.isRequired,
  handleFieldUpdate: PropTypes.func.isRequired,
  line_1: PropTypes.string.isRequired,
  line_2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  styles: PropTypes.object,
  errors: PropTypes.object,
};

export default AddressForm;
